.HPageContainer {
    min-height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  
  .image-grid {
    display: grid;
    gap: 20px;
  }
  
  .grid-item {
    width: 100%;
    max-width: 300px;
    height: 300px;
    object-fit: cover;
    cursor: pointer;
  }
  
  @media screen and (max-width: 768px) {
    .image-grid {
      grid-template-columns: 1fr;
    }
  }
  
  @media screen and (min-width: 769px) {
    .image-grid {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  