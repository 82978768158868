footer {
    background-color: #333;
    color: white;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  footer div:first-child {
    flex: 1;
  }
  
  footer div:last-child {
    display: flex;
    align-items: center;
  }
  
  footer div:last-child a {
    color: white;
    margin-left: 10px;
    text-decoration: none;
    display: flex;
    align-items: center;
  }
  
  footer div:last-child img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  